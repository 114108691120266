<template>
  <div>
    <b-navbar
      type="dark"
      class="header-navbar bg-dark-main d-block py-50 px-2"
    >
      <b-row>
        <b-col class="col-6 col-sm-4 col-md-2 col-xl-4 v-center justify-content-lg-start">
          <b-navbar-brand>
            <logo />
          </b-navbar-brand>
        </b-col>
        <b-col class="col-6 col-xl-4 d-none d-md-flex v-center justify-content-center">
          <external-buttons />
        </b-col>
        <b-col class="col-6 col-sm-8 col-md-4 v-center justify-content-end">
          <b-navbar-nav class="nav align-items-center">
            <b-nav-item-dropdown
              right
              toggle-class="d-flex pl-0 align-items-center dropdown-user-link "
              class="dropdown-user"
              no-caret
            >
              <!-- Using 'button-content' slot -->
              <template #button-content>
                <b-avatar
                  size="45"
                  class="bg-light-primary vh-center"
                >
                  <feather-icon
                    icon="UserIcon"
                    size="24"
                    class="icon-centering"
                  />
                </b-avatar>
                <div class="user-nav mr-0 ml-1 align-items-start d-none d-sm-block">
                  <p class="user-name font-weight-bolder m-0 mb-n25 user-text-limit">
                    {{ user.firstName }} {{ user.lastName }}
                  </p>
                  <p class="text-placeholder mb-0 font-size-14 user-text-limit">
                    {{ user.email }}
                  </p>
                </div>
              </template>
              <b-dropdown-item
                link-class="d-flex d-sm-none justify-content-center flex-column"
              >
                <p class="text-primary user-name font-size-16 font-weight-bolder m-0 mb-n25 user-text-limit">
                  {{ user.firstName }} {{ user.lastName }}
                </p>
                <p class="text-header mb-0 font-size-14 user-text-limit">
                  {{ user.email }}
                </p>
              </b-dropdown-item>
              <b-dropdown-divider class="d-sm-none" />
              <b-dropdown-item
                link-class="d-flex align-items-center"
                @click="logout"
              >
                <feather-icon
                  size="16"
                  icon="LogOutIcon"
                  class="mr-50"
                />
                <span>Logout</span>
              </b-dropdown-item>
            </b-nav-item-dropdown>
          </b-navbar-nav>
        </b-col>
      </b-row>
    </b-navbar>
    <div class="d-md-none mt-2">
      <external-buttons />
    </div>
  </div>
</template>

<script>
import {
  BNavItemDropdown, BNavbar, BNavbarNav, BNavbarBrand, BAvatar, BDropdownItem, BDropdownDivider, BRow, BCol,
} from 'bootstrap-vue'
import { get } from 'vuex-pathify'
import Logo from '@/components/Logo.vue'
import ExternalButtons from '../views/dashboard/components/ExternalButtons.vue'

export default {
  components: {
    BNavItemDropdown,
    BNavbar,
    BNavbarNav,
    BNavbarBrand,
    Logo,
    BAvatar,
    BDropdownItem,
    BDropdownDivider,
    BRow,
    BCol,
    ExternalButtons,
  },
  data() {
    return {

    }
  },
  computed: {
    ...get('auth', ['user']),
  },
  methods: {
    logout() {
      this.$store.dispatch('auth/logout')
    },
  },
}
</script>
<style lang="scss">
.icon-centering{
  margin-bottom: 3px;
  margin-left: 1px;
}

.user-name{
  color: #d0d2d6;
}
.dropdown-toggle::after{
  display:none!important;
}

.header-navbar{
  z-index: 2;
  .navbar-brand{
    svg {
      height: 29px;
      margin-right: 5px;
    }
  }
  .dropdown-toggle{
    &:focus-visible{
      outline: unset!important;
    }
  }
  .dropdown-menu{
    background-color: #343a40;
    border: none;
    border-radius: 0;

    .dropdown-item{
      color: #d0d2d6;

      &:hover{
        background: transparent;
        color: white;
      }
    }
  }
}
</style>
