import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import { dispatch } from 'vuex-pathify'
import router from '@/router'
import pathify from './pathify'

// Modules

import modules from './modules'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules,
  plugins: [
    pathify.plugin,
    createPersistedState({
      paths: [
        'auth.token',
        'auth.user',
        'affiliate.affiliate',
      ],
    }),
  ],
  strict: process.env.DEV,
  actions: {
    clearModules(context) {
      // eslint-disable-next-line no-underscore-dangle
      Object.keys(this._actions)
        .forEach(k => {
          if (k.indexOf('/clearModule') > -1) {
            context.dispatch(k)
          }
        })
    },
  },
})

store.subscribe((mutation, state) => {
  if (mutation.type === 'auth/SET_TOKEN') {
    if (state.auth.token) {
      dispatch('affiliate/getAffiliate', {}, { root: true }).then(() => {
        router.push('/')
      })
    } else {
      router.push('/login')
    }
  }
})

window.store = store

export default store
