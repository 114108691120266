<template>
  <div class="custom-scroll-body text-main">
    <h5><span style="font-weight: 400;">1. General Provisions</span></h5>
    <p><span style="font-weight: 400;">These Terms and Conditions of Affiliate Program Use (hereinafter also as &ldquo;Terms and Conditions&rdquo;) govern the legal relationship between the Participant of this Program (hereinafter also as &ldquo;Affiliate&rdquo;) and E8 Funding LLC, registered office at </span><span
      style="font-weight: 400;"
    >100 Crescent Ct, Unit 700,</span><span style="font-weight: 400;"> Dallas, TX USA (hereinafter also as the &ldquo;Provider&rdquo;), as the Provider of the Affiliate Program.</span>
    </p>

    <h5><span style="font-weight: 400;">2. Definition and Interpretation of Terms:</span></h5>

    <ul>
      <li aria-level="1">
        <strong>Visitor</strong>
      </li>
    </ul>
    <p><span style="font-weight: 400;">is a person who is not the Provider or the Affiliate and who visits Advertising Website;</span>
    </p>
    <ul>
      <li aria-level="1">
        <strong>Affiliate</strong>
      </li>
    </ul>
    <p><span style="font-weight: 400;">is a natural person or a legal person who concludes the Contract with the Provider;</span>
    </p>
    <ul>
      <li aria-level="1">
        <strong>Provider</strong>
      </li>
    </ul>
    <p><span style="font-weight: 400;">is as a term defined in Article 1 above;</span></p>
    <ul>
      <li aria-level="1">
        <strong>Affiliate link</strong>
      </li>
    </ul>
    <p><span style="font-weight: 400;">is a special unique URL, assigned to the Affiliate by the Provider, which, after it is inserted in the Advertising area, acts as a link leading to E8markets.com;</span>
    </p>
    <ul>
      <li aria-level="1">
        <strong>Advertising area</strong>
      </li>
    </ul>
    <p><span style="font-weight: 400;">is advertising space displayed on </span><span style="font-weight: 400;">Advertising Website</span><span
      style="font-weight: 400;"
    > with Affiliate link which contains a promotional message regarding the services offered by the Provider and a functional link leading to the website e8markets.com;</span>
    </p>
    <ul>
      <li aria-level="1">
        <strong>Advertising Website</strong>
      </li>
    </ul>
    <p><span style="font-weight: 400;">is the website to which the Affiliate has a legal relationship and on which the Affiliate link is placed;</span>
    </p>
    <ul>
      <li aria-level="1">
        <strong>Reward to the Affiliate</strong>
      </li>
    </ul>
    <p><span style="font-weight: 400;">is the reward which the Provider undertakes to pay the Affiliate for the fulfilment of the conditions stated in Article 5 of these Terms and Conditions;</span>
    </p>
    <ul>
      <li aria-level="1">
        <strong>Partners Bonus</strong>
      </li>
    </ul>
    <p><span style="font-weight: 400;">Is the percentage share that is determined after the registration as stated in article 3.1 below.</span>
    </p>
    <ul>
      <li aria-level="1">
        <strong>Terms and Conditions</strong>
      </li>
    </ul>
    <p><span style="font-weight: 400;">are as a term defined in Article 1 above;</span></p>
    <ul>
      <li aria-level="1">
        <strong>Customer Account</strong>
      </li>
    </ul>
    <p><span style="font-weight: 400;">is a customer account of the Affiliate at e8markets.com</span></p>
    <ul>
      <li aria-level="1">
        <strong>Contract</strong>
      </li>
    </ul>
    <p><span style="font-weight: 400;">is a contract on commercial cooperation concluded between the Affiliate and the Provider, the content of which is comprised in these Terms and Conditions;</span>
    </p>
    <ul>
      <li aria-level="1">
        <strong>Contract on Analytical Services</strong>
      </li>
    </ul>
    <p><span style="font-weight: 400;">is a Contract on the provision of a Demo account in the extent of individual variants offered by the Provider concluded between the Provider and the Visitor, the General Terms and Conditions of which are available at: https://e8markets.com/terms-conditions/;</span>
    </p>
    <ul>
      <li aria-level="1">
        <strong>The Main Website</strong>
      </li>
    </ul>
    <p><span style="font-weight: 400;">is the website www.e8markets.com, operated by the Provider.</span></p>
    <h5><span style="font-weight: 400;">3. REGISTRATION AND CONTRACT CONCLUSION</span></h5>
    <p><span style="font-weight: 400;">3.1 Before concluding the contract, the Affiliate submits a request at https://e8markets.com/partners/ by completing and submitting the registration form to the Provider, expressing their consent to be bound by the Terms and Conditions. Upon registration, the Affiliate receives an email from the Provider confirming the registration, with reference to his or her Customer Account. The Affiliate undertakes to keep all information about him or herself in his or her Partners Account up to date for the entire duration of the Contract.</span>
    </p>

    <p><span style="font-weight: 400;">3.2 Before concluding the Contract, the Affiliate has the right to have the Advertising Website examined. The Contract is concluded when the e-box of the Affiliate which the Affiliate had stated the address of at registration receives a confirmation e-mail message stating that the Provider accepts the conclusion of the Contract. The content of the Contract is comprised in these Terms and Conditions.</span>
    </p>

    <h5><span style="font-weight: 400;">4. OBLIGATIONS OF THE AFFILIATE</span></h5>
    <p><span style="font-weight: 400;">4.1 The Affiliate declares that he or she has carefully read these Terms and Conditions and that he or she is eligible to conclude and fulfil the obligations of this Contract. The Affiliate further undertakes to and declares that:</span>
    </p>

    <ul>
      <li
        style="font-weight: 400;"
        aria-level="1"
      >
        <strong>a)</strong><span style="font-weight: 400;"> he or she is not an employee of the Provider;</span>
      </li>
      <li
        style="font-weight: 400;"
        aria-level="1"
      >
        <strong>b)</strong><span style="font-weight: 400;"> Advertising Website is not created solely for the purpose of displaying advertisement;</span>
      </li>
      <li
        style="font-weight: 400;"
        aria-level="1"
      >
        <strong>c)</strong><span style="font-weight: 400;"> the purpose of Advertising Website is not the dissemination of content that is (i) in violation of the legal order or principles of morality, or (ii) xenophobic, racist, pornographic, cruel or abusive;</span>
      </li>
      <li
        style="font-weight: 400;"
        aria-level="1"
      >
        <strong>d)</strong><span style="font-weight: 400;"> Advertising Website has the character of a finished web page, standard appearance and does not summon doubts as to the trustworthiness of its content;</span>
      </li>
      <li
        style="font-weight: 400;"
        aria-level="1"
      >
        <strong>e)</strong><span style="font-weight: 400;"> the location of the Advertising Area on Advertising Website will not lead to the diminishing of the Provider&rsquo;s reputation or any other injury to the Provider;</span>
      </li>
      <li
        style="font-weight: 400;"
        aria-level="1"
      >
        <strong>f)</strong><span style="font-weight: 400;"> he or she shall not modify the Advertising Area or the Affiliate Link;</span>
      </li>
      <li
        style="font-weight: 400;"
        aria-level="1"
      >
        <strong>g)</strong><span style="font-weight: 400;"> he or she shall place the Advertising Area only on Advertising Website he or she had stated at registration;</span>
      </li>
      <li
        style="font-weight: 400;"
        aria-level="1"
      >
        <strong>h)</strong><span style="font-weight: 400;"> he or she shall not send out emails or act on behalf of the Provider or give such impression;</span>
      </li>
      <li
        style="font-weight: 400;"
        aria-level="1"
      >
        <strong>i)</strong><span style="font-weight: 400;"> he or she shall not violate trademarks or reputation of the Provider; and</span>
      </li>
      <li
        style="font-weight: 400;"
        aria-level="1"
      >
        <strong>j)</strong><span style="font-weight: 400;"> he or she shall not transfer or sign over his or her obligations arising from the Contract without the prior written consent of the Provider.</span>
      </li>
    </ul>

    <p><span style="font-weight: 400;">4.2 </span><span style="font-weight: 400;" /><span style="font-weight: 400;">At registration, the Affiliate shall state, inter alia, the URL of Advertising Website(s). Throughout Contract duration, the Affiliate is entitled to propose another Website via his or her Customer Account. Advertising Website, as well as the proposal of another Website on the part of the Affiliate are subjected to the approval of the Provider. At any time throughout the Contract duration, the Provider is entitled to decide that the Affiliate must not place The Main Website&nbsp; at any of the proposed Websites. In the event that the Provider notifies the Affiliate stating that he or she must not place The Main Website&nbsp; at a proposed Website, the Provider is also entitled to terminate The Contract without notice period.</span>
    </p>

    <p><span style="font-weight: 400;">4.3 </span><span style="font-weight: 400;" /><span style="font-weight: 400;">After the conclusion of the Contract, the Affiliate shall obtain an Affiliate Link from the Provider. The Affiliate undertakes to put the Affiliate Link on his or her Website and verify that by placing Affiliate Link on Advertising Website, The Main Website&nbsp; including a functional link to e8markets.com was established.</span>
    </p>

    <p><span style="font-weight: 400;">4.4 </span><span style="font-weight: 400;" /><span style="font-weight: 400;">Only one The Main Website&nbsp; (including its shifts in any direction) may be placed at any given sub-page of the Advertising Website.</span>
    </p>

    <p><span style="font-weight: 400;">4.5 </span><span style="font-weight: 400;" /><span style="font-weight: 400;">When inserting the URL Link into the Advertising Area , it is forbidden to use automatic redirection to another page, automatic opening of new browser tabs (so-called auto-hit systems, pop-up, pop-under), or loading the Provider&rsquo;s pages into the so-called iframe.</span>
    </p>

    <p><span style="font-weight: 400;">4.6 </span><span style="font-weight: 400;" /><span style="font-weight: 400;">Immediately after placing the URL Link&nbsp; on Advertising Website, the Affiliate undertakes to send the Provider a link to all the subpages of Advertising Website where The Main Website&nbsp; is placed. The Affiliate shall notify the Provider of any change to the placement of the Advertising area on the Advertising Website within 72 hours of such change.</span>
    </p>

    <h5><span style="font-weight: 400;">5. REWARD TO THE AFFILIATE</span></h5>

    <p><span style="font-weight: 400;">5.1 - The right to Reward to the Affiliate arises upon the fulfilment of the following conditions:</span>
    </p>
    <ol>
      <li><strong>a)</strong><span style="font-weight: 400;"> the Visitor has clicked on the link positioned in the Advertising Area which redirected the Visitor to e8markets.com, and</span>
      </li>
      <li><strong>b)</strong><span style="font-weight: 400;"> the Visitor concluded a Contract on Analytical Services within one month of the first access to e8markets.com via the link that is located in Advertising Area, and</span>
      </li>
      <li><strong>c)</strong><span style="font-weight: 400;"> the Contract on Analytical Services with the Visitor is not terminated within 14 days of its conclusion.</span>
      </li>
      <li><strong>d)</strong><span style="font-weight: 400;"> the customer has not applied another promo code or discount at checkout (if users apply a discount to any purchase the affiliate will be eligible for reward on there next full price purchase)</span>
      </li>
    </ol>

    <p><span style="font-weight: 400;">5.2 - To avoid doubt, it is stipulated that the Affiliate is not entitled to Reward for the sole placement of the Advertising Area on Advertising Website.</span>
    </p>

    <p><span style="font-weight: 400;">5.3 - The Affiliate&rsquo;s right to the payment of the Reward to the Affiliate in the amount and on the basis of analytical measurements by the Provider arises in the following amounts:</span>
    </p>
    <ol>
      <li><span style="font-weight: 400;">a) Partners Bonus</span><span style="font-weight: 400;" /><span
        style="font-weight: 400;"
      >from the price of the respective variant according to the Contract on Analytical Services, if concluded with a Visitor who in the past has not concluded a Contract on Analytical Services.</span>
      </li>
    </ol>

    <p><span style="font-weight: 400;">5.4 - In the event that the Affiliate is an entrepreneur, the Reward to the Affiliate will be paid by the Provider in the form of money settlement. In the event that the Affiliate is a non-entrepreneurial person, the Reward to the Affiliate will be paid in the form of discounts on the services provided by the Provider of the Affiliate&rsquo;s choice.</span>
    </p>

    <p><span style="font-weight: 400;">5.5 - The Provider shall notify the Affiliate of the amount of the Reward to the Affiliate for the preceding month to the email the Affiliate as listed in the Customer Account.</span>
    </p>

    <p><span style="font-weight: 400;">5.6- The Provider shall pay the Reward to the Affiliate within 30 days of receipt of the notice of the amount of Reward to the Affiliate reached cashless to the account of the Affiliate, on the basis of an invoice created by the Provider and confirmed by the Affiliate, assuming the amount of the Reward to Affiliate exceeds the sum of at least $200 USD in the respective month. The Reward to the Affiliate which has not been charged within 12 months from the date on which it was first possible to be charged of the Provider shall be statute-barred. The Reward to the Affiliate shall be deemed paid upon its debiting from the Provider&rsquo;s bank account in favour of the bank account of the Affiliate.</span>
    </p>

    <p><span style="font-weight: 400;">5.7 - An Affiliate who is an entrepreneur is required to pay their own tax in the amount according to the effective legislation if the payment is subject to this tax.</span>
    </p>

    <p><span style="font-weight: 400;">5.8 - An Affiliate bonus is only eligible from first purchased order by the Visitor, that has signed up under you as "Affiliate" and used yours Affiliate Link and wasn't already a client of E8 Funding LLC.</span>
    </p>
    <h5><span style="font-weight: 400;">6. CONTRACT DURATION OF CONTRACT AND TERMINATION</span></h5>
    <p><span style="font-weight: 400;">6.1 - This Contract is concluded for an indefinite period.</span></p>

    <p><span style="font-weight: 400;">6.2 - Both the Provider and the Affiliate are entitled to withdraw from the Contract with a one-month notice period which starts running on the first day of the month following the month when the notification was delivered to the other Contracting Party.</span>
    </p>

    <p><span style="font-weight: 400;">6.3 - The Provider is entitled to terminate the Contract without notice if the Affiliate breaches any of the obligations contained in article 4.1 hereof.</span>
    </p>

    <p><span style="font-weight: 400;">6.4 - After the termination of this Contract, the Affiliate undertakes to promptly remove the Advertising Area from the Advertising Website.</span>
    </p>

    <h5><span style="font-weight: 400;">7. General Provisions</span></h5>
    <p><span style="font-weight: 400;">7.1 - The Affiliate and the Provider undertake that neither of them shall disclose confidential information obtained in the performance of the Contract from the other Party to any third party, in particular data on the Reward of the Provider and the number of Contracts on Analytical Services concluded deriving from them.</span>
    </p>

    <p><span style="font-weight: 400;">7.2 - In the event that any provision of these Terms and Conditions becomes invalid or ineffective for any reason, this fact does not cause the invalidity or ineffectiveness of the rest of the Terms and Conditions.</span>
    </p>
    <p><span style="font-weight: 400;">7.3 - The Provider reserves the right to change the content of these Terms and Conditions.&nbsp;</span>
    </p>
    <p><span style="font-weight: 400;">The updated version of the Terms and Conditions will always be published on https://e8markets.com/partners and the information on the updated version along with the updated Terms and Conditions will be sent to the Affiliate&rsquo;s e-mail address. The Affiliate has the right to refuse the changes to the Terms and Conditions and for this reason to terminate the Contract within the notice period of 3 working days from the date he or she was notified of the changes to the Terms and Conditions. In the event of a conflict in the Terms and Conditions, the Terms and Conditions published under the link listed in this Article of the Terms and Conditions shall be deemed valid and binding.</span>
    </p>

    <p><span style="font-weight: 400;">7.4 - These Terms and Conditions and the Contract shall be governed by The State of Texas, USA.</span>
    </p>

    <p><span style="font-weight: 400;">7.5 - These Terms and Conditions enter into force on October 1, 2021.</span></p>
  </div>
</template>

<script>
export default {
  name: 'TermsVue',
}
</script>
