<template>
  <div>
    <div
      class="app-layout"
      :class="isAuthenticated? 'authenticated':'login'"
    >
      <navbar v-if="isAuthenticated" />
      <b-container class="mt-3 app-container">
        <router-view />
      </b-container>
      <app-footer />
    </div>
  </div>
</template>

<script>
import { BContainer } from 'bootstrap-vue'
import Navbar from './Navbar.vue'
import AppFooter from './AppFooter.vue'

export default {
  components: {
    AppFooter,
    Navbar,
    BContainer,
  },
  data() {
    return {}
  },
  computed: {
    isAuthenticated() {
      return this.$store.getters['auth/isAuthenticated']
    },
  },
}
</script>
<style lang="scss">
.app-layout {
  background-color: $dark-body;
  min-height: 100vh;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .panel-footer {
    padding: 20px 0;
  }

  .app-container {

  }
}
</style>
