import { make } from 'vuex-pathify'
import * as defaults from './defaults'

const state = () => ({
  apiResponseState: { ...defaults.API_RESPONSE_STATE_TEMPLATE },
})

const actions = {
  updateApiResponseState({ commit }, response) {
    commit('SET_API_RESPONSE_STATE', response)
  },

  clearApiResponseState({ commit }) {
    commit('SET_API_RESPONSE_STATE', { ...defaults.API_RESPONSE_STATE_TEMPLATE })
  },
}

const getters = {
  ...make.getters(state),
}

const mutations = {
  ...make.mutations(state),
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
}
