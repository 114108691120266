import axiosInstance from '@/plugins/axios'

export default {
  login(credentials) {
    const url = 'oauth2/token'
    const data = {
      ...credentials,
      client_id: process.env.VUE_APP_CLIENT,
      client_secret: process.env.VUE_APP_SECRET,
      grant_type: 'password',
      scope: 'affiliate',
    }
    return axiosInstance.post(url, data)
      .then(response => response.data)
  },

  getUser() {
    const url = 'users/me'
    return axiosInstance.get(url)
      .then(response => response.data)
  },

  socialAuth(network, redirectUrl) {
    const url = 'oauth2/social/auth'
    const data = {
      client_id: process.env.VUE_APP_CLIENT,
      client_secret: process.env.VUE_APP_SECRET,
      network,
      redirectUrl,
    }

    return axiosInstance.post(url, data)
      .then(response => response.data)
  },

  socialToken(network, redirectUrl, code) {
    const url = 'oauth2/social/token'
    const data = {
      client_id: process.env.VUE_APP_CLIENT,
      client_secret: process.env.VUE_APP_SECRET,
      network,
      redirectUrl,
      code,
    }

    return axiosInstance.post(url, data)
      .then(response => response.data)
  },
}
