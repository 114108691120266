<template>
  <div class="container">
    <div
      class="panel-footer d-flex justify-content-between w-100 align-items-center flex-column flex-sm-row text-main px-0"
    >
      <span>
        © E8 Markets {{ new Date().getFullYear() }}, All rights reserved
      </span>
      <b-link
        v-b-modal.termsModal
        class="text-decoration-underline text-main"
      >
        Terms and conditions
      </b-link>
      <b-modal
        id="termsModal"
        scrollable
        title="Terms and conditions"
        ok-only
        ok-title="Close"
        size="xl"
        content-class="bg-dark-main terms-modal"
      >
        <terms />
      </b-modal>
      <span class=" d-lg-flex d-none">Created with ❤️ for trading</span>
    </div>
  </div>
</template>

<script>
import { BLink, BModal } from 'bootstrap-vue'
import Terms from '@/components/Terms.vue'

export default {
  components: {
    BLink,
    BModal,
    Terms,
  },
}
</script>

<style lang=scss>
  .terms-modal {
 background-color: #30D5F1;
    .close {
      color: white !important;
    }
}
</style>
