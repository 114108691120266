import Vue from 'vue'
import { BootstrapVue, IconsPlugin, BadgePlugin } from 'bootstrap-vue'
import Toast from 'vue-toastification'
import VueSweetalert2 from 'vue-sweetalert2'
import FeatherIcon from '@/components/FeatherIcon.vue'

import router from './router'
import store from './store'
import App from './App.vue'

// import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap-vue/dist/bootstrap-vue.css'
// import 'sweetalert2/dist/sweetalert2.min.css'
import 'vue-toastification/dist/index.css'

const options = {
  icon: false,
  maxToasts: 20,
  transition: 'Vue-Toastification__fade',
  position: 'top-right',
  timeout: 4000,
  toastClassName: 'bg-dark-main',
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  closeButton: 'button',
  rtl: false,
}
export const $themeColors = {
}

Vue.config.productionTip = false

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(BadgePlugin)
Vue.use(VueSweetalert2)
Vue.use(Toast, options)
Vue.component(FeatherIcon.name, FeatherIcon)

// import assets styles
require('@/assets/scss/style.scss')

Vue.mixin({
  methods: {
    showLoader(show = true, transparent = false) {
      const appLoading = document.getElementById('loading-bg')
      if (appLoading) {
        if (show) {
          appLoading.classList.remove('hide')
          if (transparent) {
            appLoading.classList.add('transparent')
          }
          setTimeout(() => {
            appLoading.style.display = 'block'
          }, 500)
        } else {
          appLoading.classList.add('hide')
          appLoading.classList.remove('transparent')
          setTimeout(() => {
            appLoading.style.display = 'none'
          }, 500)
        }
      }
    },
  },
})

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
