import axios from 'axios'
import CryptoJS from 'crypto-js'
import store from '@/store'

function generateSecurityHeaders() {
  const timestamp = Math.floor(Date.now() / 1000)

  const hmac = CryptoJS.HmacSHA256(timestamp.toString(), process.env.VUE_APP_API_SECRET)
  const headerValue = hmac.toString(CryptoJS.enc.Hex)

  return {
    'X-Request-Auth': headerValue,
    'X-Request-Nonce': timestamp * 123,
  }
}

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    'Content-Type': 'application/json; charset=UTF-8',
  },
  interceptors: {},
})

instance.interceptors.request.use(
  config => {
    store.dispatch('app/clearApiResponseState')
    const newConfig = config
    newConfig.headers = {
      ...config.headers,
      ...generateSecurityHeaders(),
      ...(store.getters['auth/isAuthenticated'] && {
        Authorization: `Bearer ${store.state.auth.token}`,
      }),
    }
    return newConfig
  },
)

instance.interceptors.response.use(
  response => {
    store.dispatch('app/updateApiResponseState', response)

    return Promise.resolve(response)
  },
  error => {
    const isAuthenticated = store.getters['auth/isAuthenticated']

    if (error.response) {
      store.dispatch('app/updateApiResponseState', error.response)

      if (error.response.status === 401 && isAuthenticated) {
        store.dispatch('auth/logout')

        return Promise.reject(error.response)
      }

      return Promise.reject(error.response)
    } if (error.message && error.message === 'Network Error') {
      const response = {
        data: {
          error,
        },
        headers: {},
        status: 500,
        statusText: 'Network Error',
      }

      return Promise.reject(response)
    }

    // eslint-disable-next-line prefer-promise-reject-errors
    return Promise.reject({
      data: {
        error,
      },
      headers: {},
      status: 500,
      statusText: 'Unknown Error',
    })
  },
)

window.axiosInstance = instance

export default instance
