const CREDENTIALS_MODEL_TEMPLATE = {
  username: null,
  password: null,
  twoFactor: '',
}

const USER_MODEL = {
  firstName: null,
  lastName: null,
}

export { CREDENTIALS_MODEL_TEMPLATE, USER_MODEL }
