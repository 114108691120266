export default [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/dashboard/Dashboard.vue'),
    meta: {
      pageTitle: 'Dashboard',
    },
  },
]
