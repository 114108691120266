import { make } from 'vuex-pathify'
import AffAPI from '@/api/affiliate'

const state = () => ({
  payouts: null,
  affiliate: null,
  payoutsArray: null,
})

const actions = {
  signUpForAffiliate({ commit }) {
    return AffAPI.postSignUp()
      .then(response => {
        commit('SET_AFFILIATE', response)
      })
  },
  getAffiliate({ commit }) {
    return AffAPI.getAffiliate().then(response => {
      commit('SET_AFFILIATE', response)
    })
  },
  editCustomCode({ commit, state }, code) {
    return new Promise((resolve, reject) => {
      AffAPI.setCustomCode(code).then(() => {
        commit('SET_AFFILIATE', { ...state.affiliate, aff_custom_key: code })
        resolve()
      }).catch(e => reject(e))
    })
  },
  getPayouts({ commit }) {
    return AffAPI.getPayouts()
      .then(response => {
        commit('SET_PAYOUTS_ARRAY', response)
        const statistics = {
          totalProfit: response.reduce((a, b) => a + (b.order.price), 0),
          totalPayout: response.reduce((a, b) => a + b.profit_amount, 0),
          totalSold: response.length,
        }

        const accountPayouts = response.reduce((groups, item) => ({
          ...groups,
          [item.order.product.account_type]: [...(groups[item.order.product.account_type] || []), item],
        }), {})
        const monthlyPayouts = {
          e8: null,
          elev8: null,
          e8tournament: null,
        }
        Object.keys(accountPayouts).forEach(i => {
          monthlyPayouts[i] = accountPayouts[i].reduce((groups, item) => {
            const date = new Date(item.createdAt)
            return {
              ...groups,
              [`${date.getFullYear()}-${date.getUTCMonth() + 1}`]: [...(groups[`${date.getFullYear()}-${date.getUTCMonth() + 1}`] || []), item],
            }
          }, {})
        })

        commit('SET_PAYOUTS', {
          ...response, statistics, monthlyPayouts,
        })
      })
  },
  clearModule({ commit }) {
    commit('SET_PAYOUTS', false)
    commit('SET_AFFILIATE', null)
  },
}

const getters = {
  ...make.getters(state),
  getMonthlyPayouts: state => type => {
    let payouts = {}
    if (type) {
      payouts = state.payouts.monthlyPayouts[type]
    } else {
      Object.values(state.payouts.monthlyPayouts).forEach(item => {
        if (item) {
          Object.keys(item).forEach(k => {
            if (!payouts[k]) {
              payouts[k] = []
            }
            payouts[k] = [...payouts[k], ...item[k]]
          })
        }
      })
    }
    const result = []
    if (payouts) {
      Object.keys(payouts).forEach(k => {
        const row = {
          month: k,
          ids: payouts[k].map(item => item.id),
          totalProducts: payouts[k].length,
          totalPayout: payouts[k].reduce((a, b) => a + b.profit_amount, 0),
          totalProfit: payouts[k].reduce((a, b) => a + (b.order.price), 0),
          askForPayout: payouts[k][0].ask_for_payout,
          isPaid: payouts[k][0].is_paid,
        }
        result.push(row)
      })
    }

    return result
  },
  isSigned(state) {
    return state.affiliate !== null && state.affiliate.sign_up_at !== null
  },
}

const mutations = {
  ...make.mutations(state),
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
}
