<template>
  <div
    id="app"
  >
    <app-layout />
  </div>
</template>

<script>
import AppLayout from './layout/AppLayout.vue'

export default {
  components: {
    AppLayout,
  },
}
</script>

<style lang=scss>
#app {
  height: 100vh;
}
</style>
