import { make } from 'vuex-pathify'
import AuthAPI from '@/api/auth'

import * as defaults from './defaults'

const state = () => ({
  appLoginPending: false,

  appRegistrationPending: false,

  credentialsModel: { ...defaults.CREDENTIALS_MODEL_TEMPLATE },

  token: null,
  user: { ...defaults.USER_MODEL },
})

const actions = {
  login({ commit, state, dispatch }, payload) {
    if (state.token) {
      return
    }
    commit('SET_APP_LOGIN_PENDING', true)

    return AuthAPI.login(payload)
      .then(response => {
        commit(
          'SET_CREDENTIALS_MODEL',
          { ...defaults.CREDENTIALS_MODEL_TEMPLATE },
        )
        commit('SET_TOKEN', response.access_token)
        dispatch('getUser')
      })
      .finally(() => {
        commit('SET_APP_LOGIN_PENDING', false)
      })
  },

  getUser({ commit, state }) {
    if (state.token) {
      return AuthAPI.getUser(state.credentialsModel)
        .then(response => {
          commit('SET_USER', response)
        })
    }
  },

  logout({ commit, dispatch, state }) {
    if (!state.token) {
      return
    }

    dispatch('clearModules', null, { root: true })

    commit('SET_USER', { ...defaults.USER_MODEL })
    commit('SET_TOKEN', null)
  },

  loginSocial({ commit, state, dispatch }, { code, redirectUrl, network }) {
    if (state.token) {
      return
    }
    commit('SET_APP_LOGIN_PENDING', true)

    return AuthAPI.socialToken(network, redirectUrl, code)
      .then(response => {
        commit('SET_TOKEN', response.access_token)
        dispatch('getUser')
      })
      .finally(() => {
        commit('SET_APP_LOGIN_PENDING', false)
      })
  },

  clearModule({ commit }) {
    commit('SET_APP_LOGIN_PENDING', false)
    commit('SET_APP_REGISTRATION_PENDING', false)
    commit('SET_CREDENTIALS_MODEL', { ...defaults.CREDENTIALS_MODEL_TEMPLATE })
    commit('SET_TOKEN', null)
    commit('SET_USER', { ...defaults.USER_MODEL })
  },

}

const getters = {
  ...make.getters(state),
  isAuthenticated(state) {
    return !!state.token
  },
}

const mutations = {
  ...make.mutations(state),
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
}
