import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import login from './login'
import dashboard from './dashboard'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    ...dashboard,
    ...login,
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
        allowAnonymous: true,
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, from, next) => {
  const isAuthenticated = store.getters['auth/isAuthenticated']
  const isSigned = store.getters['affiliate/isSigned']
  if (!isAuthenticated && !to.meta.allowAnonymous && !['login', 'register'].includes(to.name)) {
    return next('/login')
  }
  if (['login', 'register'].includes(to.name) && isAuthenticated) {
    return next('/')
  }
  if (!['signup'].includes(to.name) && !isSigned && isAuthenticated) {
    return next('/signup')
  }

  if (to.meta.pageTitle) {
    document.title = `${to.meta.pageTitle} | E8 Affiliate`
  }

  return next()
})

router.afterEach(() => {
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.classList.add('hide')
    setTimeout(() => {
      appLoading.style.display = 'none'
    }, 500)
  }
})

export default router
