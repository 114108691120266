import axiosInstance from '@/plugins/axios'

export default {
  postSignUp() {
    const url = 'affiliate/signup'

    return axiosInstance.post(url)
      .then(response => response.data)
  },
  getAffiliate() {
    const url = 'affiliate'
    return axiosInstance.get(url)
      .then(response => response.data)
  },
  getPayouts() {
    const url = 'affiliate/payouts'
    return axiosInstance.get(url)
      .then(response => response.data)
  },
  postAffiliatePayout(months) {
    const url = 'affiliate/payout'

    return axiosInstance.post(url, { months })
      .then(response => response.data)
  },
  setCustomCode(affCustomKey) {
    const url = 'affiliate/custom-code'

    return axiosInstance.post(url, { affCustomKey })
      .then(response => response.data)
  },
}
