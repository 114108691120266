<template>
  <div class="d-flex w-100 justify-content-around">
    <b-link
      href="https://e8x.e8funding.com"
      target="_blank"
    >
      <b-button
        variant="outline-info"
        class="mr-3"
      >
        E8X Dashboard
      </b-button>
    </b-link>
    <b-link
      href="https://e8markets.com"
      target="_blank"
    >
      <b-button variant="outline-primary">
        E8 Markets Web
      </b-button>
    </b-link>
  </div>
</template>

<script>
import { BButton } from 'bootstrap-vue'

export default {
  name: 'ExternalButtons',
  components: {
    BButton,
  },
}
</script>

<style scoped>

</style>
